import {CKEditor} from '@ckeditor/ckeditor5-react';
import {useState} from 'react';
import {ENVIRONMENT_VARIABLES} from "../../constant";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

type Props = {
    onChange: (value: string) => void,
    defaultData?: string
}

export const CkEditorWrapper = ({defaultData, onChange}: Props) => {
    const [label, setLabel] = useState(defaultData);

    return (
        <div style={{width: '100%'}}>
            <CKEditor
                editor={ClassicEditor}
                data={label}
                config={{
                    ckfinder: {
                        uploadUrl: `${ENVIRONMENT_VARIABLES.baseUrlServer}/upload`
                    },
                    toolbar: [
                        'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
                        '|', 'insertTable', 'mediaEmbed', '|', 'undo', 'redo', '|', 'imageUpload'
                    ],
                    // codeBlock: {
                    //     languages: [
                    //         {language: 'plaintext', label: 'Plain text'},
                    //         {language: 'typescript', label: 'TypeScript'},
                    //         {language: 'csharp', label: 'C#'},
                    //         {language: 'sql', label: 'SQL'},
                    //         {language: 'json', label: 'JSON'}
                    //     ]
                    // },
                    image: {
                        toolbar: [
                            'imageStyle:full', 'imageStyle:side', '|', 'imageTextAlternative'
                        ]
                    }
                }}
                onReady={(editor) => {
                    editor.editing.view.focus();
                }}
                onChange={(_, editor) => {
                    setLabel(editor.getData());
                    onChange(editor.getData());
                }}
            />
        </div>
    );
};
