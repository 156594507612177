import { ICreateUpdateTimeEntryFormData, ITimeEntry } from 'interfaces';
import { alertService, httpRequestService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';
import { useTranslation } from 'react-i18next';

interface IParams {
  userId: string,
  from?: Date;
  to?: Date;
}

export const useHttpRequestUpdateTimeEntry = (params: IParams) => { 

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const mutation = useMutation({
    mutationFn: (data: ICreateUpdateTimeEntryFormData) => {
      // Ajout du log pour les données envoyées
      console.log("Mutation Function Called with Data:", data);
  
      return httpRequestService
        .timeEntries
        .update(data, { showErrorMessage: true })
        .then(response => {
          console.log("Mutation Response Received:", response); // Log de la réponse réussie
          return response;
        })
        .catch(error => {
          console.error("Mutation Error Occurred:", error.response?.data || error.message); // Log des erreurs
          throw error; // Relance l'erreur pour gestion par onError
        });
    },
    onMutate: (timeEntryToUpdate) => {
      // Annuler les requêtes en cours
      queryClient.cancelQueries({ queryKey: [QUERY_KEYS.getTimeEntries, params] });

      // Récupérer les anciennes données et garantir qu'il s'agit d'un tableau
      const oldTimeEntries = queryClient.getQueryData<ITimeEntry[]>([QUERY_KEYS.getTimeEntries, params]) || [];

      // Vérification explicite pour s'assurer que c'est bien un tableau
      if (!Array.isArray(oldTimeEntries)) {
        console.warn("Expected oldTimeEntries to be an array but got:", oldTimeEntries);
        throw new Error("Cache data is not an array. Check your query setup.");
      }

      // Mettre à jour les données en mémoire cache
      queryClient.setQueryData(
        [QUERY_KEYS.getTimeEntries, params],
        oldTimeEntries.map(entry => entry.id === timeEntryToUpdate.id ? timeEntryToUpdate : entry)
      );

      // Retourner les anciennes données pour pouvoir les restaurer si nécessaire
      return { oldTimeEntries };
    },
    onSuccess: () => {
      alertService.success(t('common.processSuccessfullyCompleted'));
    },
    onError: (_, __, context) => {
      // Restaurer les anciennes données en cas d'erreur
      if (context?.oldTimeEntries) {
        queryClient.setQueryData([QUERY_KEYS.getTimeEntries, params], context.oldTimeEntries);
      }
    },
    onSettled: () => {
      // Ré-invalider les requêtes pour synchroniser les données
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeEntries, params] });
    }
  });

  return mutation;

};
