import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EIconName, EUserRole } from 'enums';
import { INavLink } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { ROUTES } from 'constant';
import { appStore } from 'store';
import { isAdminOrDevAdmin } from 'functions/isAdminOrDevAdmin.function';

const VARIABLES = {
  route: ROUTES.CONSULTING,
  defaultNavLink: ({
    url: '',
    iconProps: ({
      className: 'mx-2',
      style: {
        fontSize: 'large'
      }
    })
  }) as INavLink
};

const useModuleConsulting = () => {

  const rolesUserConnected = useSelector(appStore.userConnected.get.userConnected)?.roles ?? [];
  const _hasRolesUserConnected = isAdminOrDevAdmin(rolesUserConnected) || rolesUserConnected.includes(EUserRole.Tester);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menuLinks = _hasRolesUserConnected
    ? [
      {
        ...VARIABLES.defaultNavLink,
        name: VARIABLES.route.PROFILES.description(t),
        key: VARIABLES.route.PROFILES.id,
        onClick: () => navigate(VARIABLES.route.PROFILES.path()),
        iconProps: ({
          ...VARIABLES.defaultNavLink.iconProps,
          iconName: EIconName.QuestionnaireMirrored
        }),
        routeIds: [
          VARIABLES.route.PROFILES.id,
          VARIABLES.route.PROFILES.PROFILE.id
        ]
      },
      {
        ...VARIABLES.defaultNavLink,
        name: VARIABLES.route.SKILL_MANAGEMENT.description(t),
        key: VARIABLES.route.SKILL_MANAGEMENT.id,
        onClick: () => navigate(VARIABLES.route.SKILL_MANAGEMENT.path()),
        iconProps: ({
          ...VARIABLES.defaultNavLink.iconProps,
          iconName: EIconName.FavoriteStarFill
        }),
        routeIds: [VARIABLES.route.SKILL_MANAGEMENT.id]
      },
      {
        ...VARIABLES.defaultNavLink,
        name: VARIABLES.route.SETTINGS.description(t),
        key: VARIABLES.route.SETTINGS.id,
        onClick: () => navigate(VARIABLES.route.SETTINGS.path()),
        iconProps: ({
          ...VARIABLES.defaultNavLink.iconProps,
          iconName: EIconName.Settings
        }),
        routeIds: [VARIABLES.route.SETTINGS.id]
      }
    ]
    : [];

  return ({
    menuLinks
  });

};

export default useModuleConsulting;
