import imgUnauthorize from 'assets/images/png/unauthorize-1000x1000.png';
import { ActionButton, Stack, Text } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { httpRequestService } from 'services';
import { IHasIsLoading } from 'interfaces';
import { EUserRole } from 'enums';
import { appStore } from 'store';
import './Guard.scss';
import { isAdminOrDevAdmin } from 'functions/isAdminOrDevAdmin.function';
import { IStore } from 'store';

interface IProps {
  children?: JSX.Element;
  rule?: 'none' | 'RequireAdminConnected' | (() => boolean);
}

const VARIABLES = {
  initState: {
    isLoading: true
  } as IHasIsLoading
};

const Guard = ({ children = <></>, rule = 'RequireAdminConnected' }: IProps) => {

  const _hasRolesUserConnected = useSelector((state: IStore) => {
    const roles = appStore.userConnected.get.userConnected(state)?.roles ?? [];
    return isAdminOrDevAdmin(roles) || roles.includes(EUserRole.Tester);
  });
  const _emailUserConnected = useSelector(appStore.userConnected.get.email);
  const [state, setState] = useState<IHasIsLoading>(VARIABLES.initState);
  const _idUserConnected = useSelector(appStore.userConnected.get.id);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(
    () => {
      const timeout = setTimeout(
        () => setState(l => ({ ...l, isLoading: false })),
        1500
      );

      return () => {
        clearTimeout(timeout);
      };
    },
    []
  );

  const handleOnClickBussonSignOutRedirect = () => {
    appStore.userConnected.set.set(dispatch, l => ({ ...l, isLoggingOut: true }));
    httpRequestService.azureAD.logoutRedirect();
  };

  if (rule === 'RequireAdminConnected' && _hasRolesUserConnected) {
    return children;
  }

  if (state.isLoading) {
    return <></>;
  }

  return (
    <Stack className='app-guard ms-fadeIn500' horizontal horizontalAlign='center'>
      <img src={imgUnauthorize} alt='Forbidden' />
      <Stack verticalAlign='center'>
        <Text variant='xxLarge' className=' ms-2 mb-3'>
          403 - {t('errors.forbidden.title')}
        </Text>
        <div className='app-guardContent ms-2 mb-3'>
          <Text variant='medium' className='mb-3'>
            <div dangerouslySetInnerHTML={{ __html: t('errors.forbidden.description').replace('#', _emailUserConnected) }} />
          </Text>
          <Text variant='smallPlus'>
            <div className='ms-fontColor-gray90 mt-3'>
              {new Date().toFormat('DD/MM/YYYY HH:mm:ss')}
            </div>
            <div className='ms-fontColor-gray90'>
              {_idUserConnected}
            </div>
          </Text>
        </div>
        <ActionButton className='text-green' onClick={handleOnClickBussonSignOutRedirect}>
          {t('common.signOutReconnect')}
        </ActionButton>
      </Stack>
    </Stack>
  );

};

export default memo(Guard);
