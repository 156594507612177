import { ControlledDropdown, ControlledRichTextEditor, ControlledTextField, FilesDataTableHandler } from 'components';
import { useDropdownOptionsTicketCriticality, useDropdownOptionsTicketType, useStore } from 'hooks';
import { IBaseFile, ICreateTicketFormData } from 'interfaces';
import { Control, UseFormSetValue } from 'react-hook-form';
import { PivotItem, Stack, Pivot } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { nameof } from 'functions';
import { memo } from 'react';
import store from './store';
import { CkEditorWrapper } from 'components/ckEditor/CkEditorWrapper';

interface IProps {
  setValue: UseFormSetValue<ICreateTicketFormData>;
  control: Control<any>;
}

const CreateTicketForm = ({ control, setValue }: IProps) => {

  const { options: optionsTicketCriticality, optionRender: optionRenderTicketCriticality, titleRender: titleRenderTicketCriticality } = useDropdownOptionsTicketCriticality();
  const { options: optionsTicketType, optionRender: optionRenderTicketType, titleRender: titleRenderTicketType } = useDropdownOptionsTicketType();
  const _description = useSelector(store.get.description);
  const _files = useSelector(store.get.files);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  useStore(store);

  const handleOnChangeDescription = (description: string) => {
    setValue('description', description);
    store.set.set(dispatch, l => ({ ...l, description }));
  };

  const handleOnAddFile = (file: IBaseFile) => new Promise<void>(
    resolve => {
      if (_files.some(l => l.name === file.name) === false) {
        const files = [..._files, file];
        setValue?.('attachments', files);
        store.set.set(dispatch, l => ({ ...l, files }));
      }
      resolve();
    }
  );

  const handleOnRemoveFile = (file: IBaseFile) => new Promise<void>(
    resolve => {
      const files = [..._files.filter(l => l.name !== file.name)];
      setValue?.('attachments', files);
      store.set.set(dispatch, l => ({ ...l, files }));
      resolve();
    }
  );

  return (
    <Stack className='mt-2' verticalFill>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <ControlledDropdown
          className='mb-2 w-100'
          control={control}
          onRenderOption={optionRenderTicketType}
          onRenderTitle={titleRenderTicketType}
          options={optionsTicketType}
          label={t('common.type')}
          rules={{ required: t('common.formError.required').toString() }}
          name={nameof<ICreateTicketFormData>('type')} />
        <ControlledDropdown
          className='mb-2 w-100'
          control={control}
          onRenderOption={optionRenderTicketCriticality}
          onRenderTitle={titleRenderTicketCriticality}
          options={optionsTicketCriticality}
          label={t('common.criticality')}
          name={nameof<ICreateTicketFormData>('criticality')} />
      </Stack>
      <ControlledTextField
        className='mb-2'
        control={control}
        label={t('common.title')}
        rules={{ required: t('common.formError.required').toString() }}
        name={nameof<ICreateTicketFormData>('title')} />
      <Pivot className='mt-2 h-100' style={{ backgroundColor: 'rgba(248,248,248,1)' }}>
        <PivotItem
          headerText={t('common.details')}
          className='p-3'>
            <CkEditorWrapper
                defaultData={_description ?? ''}
                onChange={content => handleOnChangeDescription(content)}/>
        </PivotItem>
        <PivotItem
          headerText={t('common.attachments')}
          itemIcon='Attach'
          itemCount={_files.length}
          className='p-3'>
          <FilesDataTableHandler
            files={_files}
            onAdd={handleOnAddFile}
            onRemove={handleOnRemoveFile} />
        </PivotItem>
      </Pivot>
    </Stack>
  );

};

export default memo(CreateTicketForm);
