import { ICreateUpdateTimeEntryFormData, ITimeEntry } from 'interfaces';
import { alertService, httpRequestService } from 'services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'constant';
import { useTranslation } from 'react-i18next';

type TDataVariables = ICreateUpdateTimeEntryFormData;

interface IParams {
  userId: string,
  from?: Date;
  to?: Date;
}

export const useHttpRequestCreateTimeEntry = (params: IParams) => {

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const mutation = useMutation({
    mutationFn: (data: TDataVariables) =>
      httpRequestService
        .timeEntries
        .create(data, { showErrorMessage: true }),
    onMutate: (newTimeEntry) => {
      // Annuler les requêtes en cours
      queryClient.cancelQueries({ queryKey: [QUERY_KEYS.getTimeEntries, params] });

      // Récupérer les anciennes données et s'assurer que c'est un tableau
      const oldTimeEntries = queryClient.getQueryData<ITimeEntry[]>([QUERY_KEYS.getTimeEntries, params]) || [];

      // Mise à jour des données en cache
      queryClient.setQueryData([QUERY_KEYS.getTimeEntries, params], (old: ITimeEntry[] = []) => [
        ...old,
        newTimeEntry,
      ]);

      // Retourner les anciennes données pour pouvoir les restaurer en cas d'erreur
      return { oldTimeEntries };
    },
    onSuccess: () => {
      alertService.success(t('common.processSuccessfullyCompleted'));
    },
    onError: (_, __, context) => {
      // Restaurer les anciennes données en cas d'erreur
      if (context?.oldTimeEntries) {
        queryClient.setQueryData([QUERY_KEYS.getTimeEntries, params], context.oldTimeEntries);
      }
    },
    onSettled: () => {
      // Invalider les requêtes pour synchroniser les données
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.getTimeEntries, params] });
    }
  });

  return (mutation);

};
