import { Route, RouteProps, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Guard } from 'components';
import { EUserRole } from 'enums';
import { appStore } from 'store';
import { memo } from 'react';
import { isAdminOrDevAdmin } from 'functions/isAdminOrDevAdmin.function';

type Props = {
  userRoles: EUserRole[];
  component: any;
}

const RouteGuard = ({ component, userRoles: userRole }: Props) => {

  const _userConnected = useSelector(appStore.userConnected.get.userConnected);
  const _idUserConnected = useSelector(appStore.userConnected.get.id);

  if (!_idUserConnected) {
    return <></>;
  }

  const hasAccess = _userConnected.roles.some(
    (role) =>
      userRole.includes(role) || 
      (userRole.includes(EUserRole.Admin) && isAdminOrDevAdmin(_userConnected.roles)) 
  );
  
  if (!hasAccess) {
    return <Guard rule="none" />; 
  }

  return component;

};

export default memo(RouteGuard);
