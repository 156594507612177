import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EIconName, EUserRole } from 'enums';
import { INavLink } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { ROUTES } from 'constant';
import { appStore } from 'store';
import { isAdminOrDevAdmin } from 'functions/isAdminOrDevAdmin.function';

const VARIABLES = {
  route: ROUTES.EXPORTS,
  defaultNavLink: ({
    url: '',
    iconProps: ({
      className: 'mx-2',
      style: {
        fontSize: 'large'
      }
    })
  }) as INavLink
};

const useModuleExports = () => {

  const rolesUserConnected = useSelector(appStore.userConnected.get.userConnected)?.roles ?? [];
  const _hasRolesUserConnected = isAdminOrDevAdmin(rolesUserConnected) || rolesUserConnected.includes(EUserRole.Tester);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menuLinks = _hasRolesUserConnected
    ? (
      [
        {
            ...VARIABLES.defaultNavLink,
            name: VARIABLES.route.TIME_EXPORTS_TRACKING.description(t),
            key: VARIABLES.route.TIME_EXPORTS_TRACKING.id,
            onClick: () => navigate(VARIABLES.route.TIME_EXPORTS_TRACKING.path()),
            iconProps: ({
                ...VARIABLES.defaultNavLink.iconProps,
                iconName: EIconName.TimeEntry
            }),
            routeIds: [VARIABLES.route.TIME_EXPORTS_TRACKING.id]
        }
      ]
    )
    : [];

  return ({
    menuLinks
  });

};

export default useModuleExports;
