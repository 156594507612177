import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EIconName, EUserRole } from 'enums';
import { INavLink } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { ROUTES } from 'constant';
import { appStore } from 'store';
import { isAdminOrDevAdmin } from 'functions/isAdminOrDevAdmin.function';

const VARIABLES = {
  route: ROUTES.TIME_TRACKING,
  defaultNavLink: ({
    url: '',
    iconProps: ({
      className: 'mx-2',
      style: {
        fontSize: 'large'
      }
    })
  }) as INavLink
};

const useModuleTimeTracking = () => {

  const rolesUserConnected = useSelector(appStore.userConnected.get.userConnected)?.roles ?? [];
  const _hasRolesUserConnected = isAdminOrDevAdmin(rolesUserConnected) || rolesUserConnected.includes(EUserRole.Tester);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menuLinks = _hasRolesUserConnected
    ? (
      [
        {
          ...VARIABLES.defaultNavLink,
          name: VARIABLES.route.TIME_TRACKING.description(t),
          key: VARIABLES.route.TIME_TRACKING.id,
          onClick: () => navigate(VARIABLES.route.TIME_TRACKING.path()),
          iconProps: ({
            ...VARIABLES.defaultNavLink.iconProps,
            iconName: EIconName.TimeEntry
          }),
          routeIds: [VARIABLES.route.TIME_TRACKING.id]
        },
        {
          ...VARIABLES.defaultNavLink,
          name: VARIABLES.route.CLIENTS.description(t),
          key: VARIABLES.route.CLIENTS.id,
          onClick: () => navigate(VARIABLES.route.CLIENTS.path()),
          iconProps: ({
            ...VARIABLES.defaultNavLink.iconProps,
            iconName: EIconName.People
          }),
          routeIds: [VARIABLES.route.CLIENTS.id]
        },
        {
          ...VARIABLES.defaultNavLink,
          name: VARIABLES.route.PROJECTS.description(t),
          key: VARIABLES.route.PROJECTS.id,
          onClick: () => navigate(VARIABLES.route.PROJECTS.path()),
          iconProps: ({
            ...VARIABLES.defaultNavLink.iconProps,
            iconName: EIconName.ProjectLogo16
          }),
          routeIds: [VARIABLES.route.PROJECTS.id]
        },
        {
          ...VARIABLES.defaultNavLink,
          name: VARIABLES.route.ACTIVITIES.description(t),
          key: VARIABLES.route.ACTIVITIES.id,
          onClick: () => navigate(VARIABLES.route.ACTIVITIES.path()),
          iconProps: ({
            ...VARIABLES.defaultNavLink.iconProps,
            iconName: EIconName.BulletedList
          }),
          routeIds: [VARIABLES.route.ACTIVITIES.id]
        },
        {
          ...VARIABLES.defaultNavLink,
          name: VARIABLES.route.SETTINGS.description(t),
          key: VARIABLES.route.SETTINGS.id,
          onClick: () => navigate(VARIABLES.route.SETTINGS.path()),
          iconProps: ({
            ...VARIABLES.defaultNavLink.iconProps,
            iconName: EIconName.Settings
          }),
          routeIds: [VARIABLES.route.SETTINGS.id]
        }
      ]
    )
    : [];

  return ({
    menuLinks
  });

};

export default useModuleTimeTracking;
