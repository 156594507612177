import { EUserRole } from 'enums';

const isDevelopmentEnvironment = process.env.REACT_APP_ENVIRONMENT_NAME === 'DEV';

/**
 * Vérifie si l'utilisateur satisfait la politique AdminOrDevAdmin.
 * - Admin : Accès complet.
 * - AdminUAT : Accès complet si l'environnement est Développement.
 */
export const isAdminOrDevAdmin = (roles: EUserRole[]): boolean => {
  return (
    roles.includes(EUserRole.Admin) || 
    (roles.includes(EUserRole.AdminUAT) && isDevelopmentEnvironment)
  );
};